import { React, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSanctum } from 'react-sanctum';
import axios from 'axios';
import ShoppingItem from '../components/ShoppingItem';
import NewItemForm from '../components/NewItemForm';
import ShareForm from '../components/ShareForm';
import loadingGif from '../images/loading.svg';


const ListPage = (props) => {
    const { authenticated, user } = useSanctum();
    const [listInfo, setListInfo] = useState({date: "", title: "", shoppingitems: []});
    const [sharedTo, setSharedTo] = useState([]);
    const [showAddItem, setShowAddItem] = useState(false);
    const [showShareList, setShowShareList] = useState(false);
    const [loading, setLoading] = useState(true);

    const { listId } = useParams();
    
    useEffect(() => {
        props.setPage('list');
    
        const loadListInfo =  async () => { 
            axios.get(`/api/lists/${listId}`, {})
            .then((response) => {
                const newListInfo = response.data;
                console.log("ListInfo: " + JSON.stringify(newListInfo));
                if (newListInfo.users.length > 1) {
                    setSharedTo(newListInfo.users.filter((u) => user.id !== u.id).map((u) => (u.name)));
                }
                setListInfo(newListInfo);
                setLoading(false);
            })
            .catch(function (error) {
                console.error(error);
            });
        }; 

        if (authenticated) {
            loadListInfo();
        }

    }, [authenticated, user, listId, props]);

    const changeItemStatus = (itemId, taken) => {
        console.log("ChangeItemStatus("+ itemId + ", " + taken + ")");
        axios.put(`/api/lists/${listId}/items/${itemId}`, {
            itemTaken: taken ? 1 : 0,
        }, {})
        .then((response) => {
            setListInfo(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    const addNewItem = (item) => {
        axios.post(`/api/lists/${listId}/items`, {
            item: item,
        }, {})
        .then((response) => {
            setListInfo(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
    };


    const deleteItem = (itemId) => {
        console.log("deleteItem("+ itemId + ")");
        axios.delete(`/api/lists/${listId}/items/${itemId}`, {}, {})
        .then((response) => {
            setListInfo(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    const shareList = (shareEmail) => {
        console.log("shareList "+ listId + " to " + shareEmail);
        axios.put(`/api/lists/${listId}/user/${shareEmail}`, {}, {})
        .then((response) => {
            
        })
        .catch(function (error) {
            console.error(error);
        });
    };
    
    const showAddModal = () => {
        setShowAddItem(true);
    };

    const closeAddModal = () => {
        setShowAddItem(false);
    };

    const onSubmitAdd = (event) => {
        setShowAddItem(false);
        event.preventDefault(event);
        addNewItem(event.target.item.value);
    };

    const showShareModal = () => {
        setShowShareList(true);
    };

    const onSubmitShare = (event) => {
        setShowShareList(false);
        event.preventDefault(event);
        shareList(event.target.item.value);
    };

    const closeShareModal = () => {
        setShowShareList(false);
    };

    if (loading) {
        return (
            <>
                <img className='loading-animation' src={loadingGif} alt="Loading animation" />
            </>
        );
    }

    return(
        <div className='container'>
            <h1>{listInfo.title}</h1>
            <div id="share-list-form">
                <button className='share-button' onClick={showShareModal} />
                {showShareList
                  ?  <ShareForm onSubmit={onSubmitShare} closeModal={closeShareModal} />
                  : null}
            </div>
            {sharedTo.length > 0 
                ? <p className='shared-to'>Listaa käyttää myös <b>{sharedTo.join(', ')}</b></p>
                : null }
            <div className="items">
                <h2 className="undone" aria-hidden="true">Hakematta</h2>
                {listInfo.shoppingitems.filter((item) => !item.taken).map((item) => (
                    <ShoppingItem key={item.id} item={item} toggleTaken={changeItemStatus} deleteItem={deleteItem}/>
                ))}
                <h2 className="done" aria-hidden="true">Haettu</h2>
                {listInfo.shoppingitems.filter((item) => item.taken).map((item) => (
                    <ShoppingItem key={item.id} item={item} toggleTaken={changeItemStatus} deleteItem={deleteItem}/>
                ))}
            </div>
            <div>
                <button className='add-button' onClick={showAddModal} />
                {showAddItem
                  ?  <NewItemForm onSubmit={onSubmitAdd} closeModal={closeAddModal} itemName="ostos"/>
                  : null}
            </div>
        </div>
    );
}

export default ListPage;