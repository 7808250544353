import React, { useEffect } from "react";

const NotFoundPage = (props) => {
    useEffect(() => {
        props.setPage('not_found');
    }, [props]);

    return(
        <h1>404: Page Not Found!</h1>
    );
}

export default NotFoundPage;