import React, { useEffect } from "react";
import titleImg from '../images/listax.svg';


const HomePage = (props) => {

    useEffect(() => {
        props.setPage('home');
    }, [props]);
    
    return (
        <>
            <img id='title-image' src={titleImg} alt='listax'/>
            <p>
                ListaX:lla voi tehdä ostoslistoja ja jakaa niitä muiden käyttäjien kesken. 
            </p>
            <p>    
                Tämä ohjelmisto on tehty omaksi huviksi lähinnä React.js:n opiskelemiseksi.
                Back-end on tehty PHP:llä (Laravel).
            </p>
            <p>
                HUOM! ListaX on edelleen vahvasti keskeneräinen!
            </p>
        </>
    );
}

export default HomePage;