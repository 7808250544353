import React from "react";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSanctum } from "react-sanctum";

const CreateAccountPage = (props) => {
    const { setUser } = useSanctum();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        props.setPage('new_account');
    }, [props]);

    const createAccount = async () => {
        if (password !== confirmPassword) {
            setError('Password and confirm password do not match');
            return;
        }

        axios.post(`/api/register`, {
            name: name,
            email: email,
            password: password
        })
        .then((response) => {
            const user = response.data;
            setUser(user);
            navigate('/lists');
        })
        .catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className='listax-form'>
            <h1>Luo tili</h1>
            {error && <p className="error">{error}</p>}
            <input
                placeholder="nimi"
                value={name}
                onChange={e => setName(e.target.value)} />
            <input
                placeholder="sähköposti"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            <input
                type="password"
                placeholder="salasana"
                value={password}
                onChange={e => setPassword(e.target.value)} />
            <input
                type="password"
                placeholder="salasana uudelleen"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)} />
            <button onClick={createAccount}>Luo tili</button>
            <Link className="secondary-button" to="/loginpage">Sinulla on jo tili? Kirjaudu täällä.</Link>
        </div>
    );

}

export default CreateAccountPage;