import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from "react-sanctum";
import '../css/TopAppBar.css';

const TopAppBar = (props) => {
    const { authenticated, user, signOut } = useSanctum();
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();

    const determineTitle = (page) => {
        if (page === 'home') return 'Koti';
        if (page === 'lists') return 'Listat';
        if (page === 'list') return 'Lista';
        if (page === 'about') return 'Info';
        //if (page === 'login') return 'Kirjaudu';
        return '';
    };

    const title = determineTitle(props.page);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const logIn = () => {
        navigate('/loginpage');
        setMenuVisible(false);
    };
    
    const logOut = () => {
        signOut()
        .then(() => {
            setMenuVisible(false);
            navigate('/');
        })
        .catch((e) => console.error(e));
    };

    const showProfile = () => {
        setMenuVisible(false);
        alert('Käyttäjänimi: ' + user.name);
    }
    
    return(
        <div id="top-app-bar">
            <span id='app-title'>{title}</span>
            <input id="menu__toggle" type="checkbox" checked={menuVisible} onChange={toggleMenu} />
            <label className="menu__btn" htmlFor="menu__toggle">
                <span></span>
            </label>

            { menuVisible
                ? <ul className="menu__box">
                    {authenticated 
                    ?   <>
                        <li><div className="menu__item" onClick={showProfile}>Käyttäjätiedot</div></li>
                        <li><div className="menu__item" onClick={logOut}>Kirjaudu ulos</div></li>
                        </>  
                    :   <>
                        <li><div className="menu__item" onClick={logIn}>Kirjaudu</div></li>
                        <li><a className="menu__item" href="/create-account">Luo tili</a></li>
                        </>
                    }
                  </ul>
                : null 
            }  
        </div>
    );
}

export default TopAppBar;