import React, {useState} from 'react'; 
import { Link } from "react-router-dom"

const ShoppingList = (props) =>{

    const [showContextMenu, setShowContextMenu] = useState(false); 
    const deleteItem = props.deleteItem; 
    const list = props.list;
    
    
    const handleDelete = () => {
        setShowContextMenu(false);
        deleteItem(list.id);
    };

    const toggleContextMenu = () => {
        setShowContextMenu(!showContextMenu);
    };

    const outOfFocus = () => {
        setShowContextMenu(false);
    }

    const showAlert = () => {
        setShowContextMenu(false);
        alert('Hello there!');
    };

    return (
        <div className='item-wrapper'>
            <Link key={list.id} className="list-list-item" to={`/lists/${list.id}`}>
                <span>{list.date}</span>
                <h3>{list.title}</h3>
                {list.owner
                    ? <p className='shared-by'>Tämä on jaettu lista. Tekijä: <b>{list.owner}</b></p>
                    : <p></p> }
            </Link>
            <div className='context-menu-wrapper' onBlur={outOfFocus}>
                <button className='context-menu-button' onClick={toggleContextMenu}>☰</button>
                {showContextMenu &&
                    <nav className="context-menu">
                        <ul>
                            <li className='context-menu-item'>
                                <button onMouseDown={showAlert}>
                                    Tervehdys
                                </button>
                            </li>
                            <li className='context-menu-item'>
                                <button onMouseDown={handleDelete}>
                                    Poista
                                </button>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
        </div>
        );
};

export default ShoppingList;