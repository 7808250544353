import './css/App.css';
import { useState } from 'react';
import { Sanctum } from "react-sanctum";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TopAppBar from './components/TopAppBar';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ListListPage from './pages/ListListPage';
import ListPage from './pages/ListPage';
import CreateAccountPage from './pages/CreateAccountPage';
import NotFoundPage from './pages/NotFoundPage';
import BottomNavBar from './components/BottomNavBar';

const sanctumConfig = {
  apiUrl: "https://backend.listax.kainila.fi",
  //apiUrl: "http://localhost:8000",
  csrfCookieRoute: "api/csrf-cookie",
  signInRoute: "api/login",
  signOutRoute: "api/logout",
  userObjectRoute: "api/user",
};

function App() {
  const [page, setPage] = useState('');

  const setPageName = (name) => {
    setPage(name);
  }

  return (
    <BrowserRouter>
    <div className="App">
      <Sanctum config={sanctumConfig}>
        <TopAppBar page={page}/>
        <div id="page-body">
          <Routes>
            <Route path="/" element={<HomePage setPage={setPageName}/>} />
            <Route path="/about" element={<AboutPage setPage={setPageName}/>} />
            <Route path="/lists" element={<ListListPage setPage={setPageName}/>} />
            <Route path="/lists/:listId" element={<ListPage setPage={setPageName} />} />
            <Route path="/loginpage" element={<LoginPage setPage={setPageName} />} />
            <Route path="/create-account" element={<CreateAccountPage setPage={setPageName} />} />
            <Route path="*" element={<NotFoundPage setPage={setPageName} />} />
          </Routes>
        </div>
        <BottomNavBar />
      </Sanctum>
    </div>
    </BrowserRouter>
  );
}

export default App;
