import React, {useState} from 'react'; 

const ShoppingItem = (props) =>{

    const [checked, setChecked] = useState(props.item.taken); 
    const [showContextMenu, setShowContextMenu] = useState(false); 
    const toggleTaken = props.toggleTaken;
    const deleteItem = props.deleteItem; 
    const item = props.item;
    
    const handleChange = () => { 
        const newChecked = !checked; 
        setChecked(newChecked); 
        toggleTaken(item.id, newChecked); 
    }; 

    const handleDelete = () => {
        setShowContextMenu(false);
        deleteItem(item.id);
    };

    const toggleContextMenu = () => {
        setShowContextMenu(!showContextMenu);
    };

    const showAlert = () => {
        setShowContextMenu(false);
        alert('Hello there!');
    };

    return (
        <div className='item-wrapper'>
            <input id={item.id} type="checkbox" checked={checked} onChange={handleChange}></input>
            <label htmlFor={item.id}>{item.name}</label>
            <div className='context-menu-wrapper'>
                <button className='context-menu-button' onClick={toggleContextMenu}>☰</button>
                {showContextMenu &&
                    <nav className="context-menu">
                        <ul>
                            <li className='context-menu-item'>
                                <button onClick={showAlert}>
                                    Display Greeting
                                </button>
                            </li>
                            <li className='context-menu-item'>
                                <button onClick={handleDelete}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
        </div>
        );
};

export default ShoppingItem;