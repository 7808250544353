import React, { useState, useEffect } from "react";
import { useSanctum } from "react-sanctum";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = (props) => {
    const { signIn } = useSanctum();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        props.setPage('login');
    }, [props]);
    

    const logIn = async (e) => {
        const remember = true;

        e.preventDefault();
        signIn(email, password, remember)
            .then(() => {
                console.log('Signed in');
                navigate('/lists');
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setAuthError(true);
                } else {
                    setUnknownError(true);
                    console.error(error);
                }
            });            
    };

    return(
        <div className="listax-form">
            <h1>Kirjaudu</h1>
            {authError ? <div className="alert alert-danger">Sähköposti tai salasana virheellinen.</div> : null}
            {unknownError ? <div className="alert alert-danger">Kirjautuminen epäonnistui.</div> : null}
            <input
                placeholder="Sähköpostiosoite"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            <input 
                type="password"
                placeholder="Salasana"
                value={password}
                onChange={e => setPassword(e.target.value)} />
            <button onClick={logIn}>Kirjaudu</button>
            <Link className="secondary-button" to="/create-account">Eikö sinulla ole tiliä? Luo tili täällä.</Link>
        </div>
    );
}

export default LoginPage;