import React from 'react';
import '../css/BottomNavBar.css';
import homeImg from '../images/home2.svg';
import listImg from '../images/list2.svg';
import infoImg from '../images/info2.svg';

const BottomNavBar = () => {
    
    return(
        <div id="bottom-nav-bar">
            <ul className="bottom-nav-buttons">
                <li><a href="/"><img className='bottom-nav-button-img' src={homeImg} alt='koti'/></a></li>
                <li><a href="/lists"><img className='bottom-nav-button-img' src={listImg} alt='listat'/></a></li>
                <li><a href="/about"><img className='bottom-nav-button-img' src={infoImg} alt='info'/></a></li>
            </ul>
        </div>
    );
}

export default BottomNavBar;