import React from 'react';
import "../css/NewItemForm.css";

const NewItemForm = (props) => {
return (
    <aside className='modal-cover'>
        <div className="modal-area">
          <button
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_modal-close"
            onClick={props.closeModal}
          >
            <span id="close-modal" className="_hide-visual">Sulje</span>
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          <div className="modal-body">
            <form onSubmit={props.onSubmit}>
              <label htmlFor="item">Lisää {props.itemName}</label>
              <input id="item" placeholder={props.itemName}/>
              <button type="submit">Lisää</button>
            </form>
          </div>
        </div>
    </aside>    
    );
};

export default NewItemForm;
