import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSanctum } from 'react-sanctum'; 
import axios from 'axios';
import ShoppingList from "../components/ShoppingList";
import NewItemForm from '../components/NewItemForm';
import loadingGif from '../images/loading.svg';


const ListListPage = (props) => {
    const { authenticated } = useSanctum();
    const [loading, setLoading] = useState(true);
    const [lists, setLists] = useState([]);
    const [showAddList, setShowAddList] = useState(false);

    useEffect(() => {
        props.setPage('lists');
        console.log("LIST PAGE useEffect");
        const loadLists =  () => { 
            axios.get(`/api/lists`, {})
            .then((response) => {
                setLists(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.error(error);
            });           
        };

        if (authenticated) {
            loadLists();
        } else if (authenticated != null) {
            setLoading(false);
        }
    }, [authenticated, props]);   

    
    const showModal = () => {
        setShowAddList(true);
    };

    const closeModal = () => {
        setShowAddList(false);
    };

    const addList = (name) => {
        axios.post(`/api/lists`, {
            title: name,
        }, {})
        .then((response) => {
            setLists(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    const onSubmitList = (event) => {
        setShowAddList(false);
        event.preventDefault(event);
        addList(event.target.item.value);
    };

    const deleteList = (listId) => {
        console.log("deleteList("+ listId + ")");
        axios.delete(`/api/lists/${listId}`, {}, {})
        .then((response) => {
            setLists(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
    };

    if (loading) {
        return (
            <>
                <img className='loading-animation' src={loadingGif} alt="Loading animation" />
            </>
        );
    }

    if (!authenticated) {
        return(
            <>
                <h2>Listat vain kirjautuneille</h2>
                <Link to="/loginpage"><button>Kirjaudu sisään</button></Link> nähdäksesi omat listasi!
            </>
        );
    }
    
    return(
        <>
            <div>
                {lists.length > 0 
                    ? lists.map(list => (
                        <ShoppingList key={list.id} list={list} deleteItem={deleteList}/>
                    )) 
                    : <p>Ei listoja</p>}
            </div>
            
            <div>
                <button className='add-button' onClick={showModal} />
                    {showAddList
                    ?  <NewItemForm onSubmit={onSubmitList} closeModal={closeModal} itemName={"lista"}/>
                    : null}
            </div>
        </>
    );
}

export default ListListPage;